
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    slug: {
      type: String,
      default: '',
    },
    tagType: {
      type: String,
      default: 'blog',
    },
  },
  computed: {
    route() {
      if (!this.slug) {
        return null;
      }

      switch (this.tagType) {
        case 'blog':
          return {name: 'blogs', query: {theme: this.slug}};
        case 'recept':
          return {name: 'recepten', query: {filter: this.slug}};
        default:
          return null;
      }
    },
  },
};
