
import createFavorite from '~/graphql/mutations/createFavorite';
import deleteFavorite from '~/graphql/mutations/deleteFavorite';

export default {
  props: {
    recipe: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selected: false,
    unavailable: false,
    liked_id: null,
    time: 15,
  }),
  computed: {
    totalPreparationTime() {
      const waitingTimeSplit = this.recipe.wachttijd ? this.recipe.wachttijd.split(':') : [0, 0];
      const preperationTimeSplit = this.recipe.bereidingstijd ? this.recipe.bereidingstijd.split(':') : [0, 0];

      const waitingTimeInMinutes = (+waitingTimeSplit[0]) * 60 + (+waitingTimeSplit[1]);
      const preperationTimeInMinutes = (+preperationTimeSplit[0]) * 60 + (+preperationTimeSplit[1]);
      const total = waitingTimeInMinutes + preperationTimeInMinutes;

      const minutes = total % 60;
      const hours = ('0' + ((total - minutes) / 60)).slice(-2);

      return `${hours}:${('0' + minutes).slice(-2)}`;
    },
  },
  created() {
    if (this.$auth.loggedIn) {
      this.$auth.user.favorites.forEach((favorite) => {
        if (favorite.recipe_slug === this.recipe.slug) {
          this.liked_id = favorite.id;
        }
      });
    }
  },
  methods: {
    toggleLike() {
      this.liked_id ? this.removeLike() : this.addLike();
    },
    async addLike() {
      this.loading = true;
      try {
        const {data} = await this.$apollo.mutate({
          mutation: createFavorite,
          variables: {recipe_slug: this.recipe.slug},
        });
        this.liked_id = data.CreateFavorite.id;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async removeLike() {
      this.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: deleteFavorite,
          variables: {id: this.liked_id},
        });
        this.liked_id = null;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
